@import "../abstracts/extends";
@import "../abstracts/variables";
@import "../components/color-price-case";
@import "../components/timer";
@import "../components/knife-block";
@import "../components/case-item";
@import "../components/home-category-navigation";
@import "../components/case-row";

.not-author {
  .open-cases.open-cases-breezy.open-cases-breezy,
  .open-cases.open-cases-home.open-cases-breezy {
    padding-top: 50px !important;
    background: unset;
  }

  .open-cases.open-cases-home.open-cases-breezy {
    section::before {
      top: -130px;
    }
  }

  &.sales-timer-off {
    .open-cases {
      margin-bottom: -84px;
    }
  }

  .main-banner {
    position: relative;
  }

  .open-cases-advantages {
    width: 930px;
    margin: 0 auto 42px;
    font-size: 0;
    position: relative;
    z-index: 2;

    &-item {
      display: inline-block;
      text-align: center;
      width: 33.3%;
      position: relative;

      &:before {
        @extend %before-after;
        width: 240px;
        height: 240px;
        background: url(../images/shadow-1.svg) no-repeat 0 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        opacity: 0.6;
        margin-top: -25px;
      }

      img {
        margin-bottom: 22px;
      }
    }

    .advantages-item-title {
      color: #ffc600;
      font-size: 21px;
      font-weight: 700;
      margin-bottom: 17px;
      line-height: 24px;
      margin-top: -60px;
      color: $white;
      text-shadow: 0px 0px 16px #d32ce6;
    }

    .advantages-item-descr {
      font-weight: 400;
      font-size: 16px;
      color: #744e81;
      line-height: 20px;
    }
  }

  &:not(.distributions-active) {
    .open-cases-home {
      margin-top: 0;
      background-position: 50% -42px;
      padding-top: 42px;
      height: 232px;
      min-height: 232px;
      margin-bottom: -74px;

      &:after,
      &:before {
        width: 549px;
        height: 820px;
        top: 0;
        background-position: 50% -113px;

        @include desktop() {
          display: none;
        }
      }

      &.open-cases-breezy {
        padding-top: 170px !important;
        margin-top: -30px;
        height: auto;
        min-height: auto;
        min-height: 470px;

        @media (max-width: 768px) {
          padding-top: 87px !important;
          min-height: 440px;
          height: 460px;
        }
      }
    }
  }
}

// .main[data-pathname="/"] {
//   &.not-author {
//     &.sales-timer-off {
//       .open-cases {
//         margin-bottom: -74px;
//         margin-top: -72px;
//         padding-top: 105px;
//       }
//     }
//   }
// }

.author {
  .open-cases {
    padding-top: 40px;
    margin-top: -10px;
    margin-bottom: -84px;
  }

  .distribution-wrap {
    margin-bottom: 30px;
  }

  &.distributions-active {
    .distribution-wrap {
      background: none;
    }
  }

  .main-bannes-sales {
    & ~ .open-cases {
      &.open-cases-event {
        &.open-cases-breezy {
          position: relative;

          @include before() {
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, #240943 12.76%, rgba(38, 9, 70, 0) 33.15%),
              radial-gradient(28.23% 112.22% at 50% 58.59%, rgba(7, 11, 89, 0.288) 45.82%, rgba(69, 68, 117, 0) 94.24%);
            top: 0;
            left: 0;

            @media (max-width: 1280px) {
              display: block !important;
              background: linear-gradient(180deg, #240943 12.76%, rgba(38, 9, 70, 0) 33.15%),
                linear-gradient(0deg, rgba(19, 7, 44, 0.4), rgba(19, 7, 44, 0.4));
            }
          }

          & > section {
            position: relative;

            @include before() {
              width: 100%;
              height: 100%;
              background: linear-gradient(180deg, #5a036f 14%, rgba(65, 9, 79, 0) 54.79%);
              opacity: 0.4;
              top: -168px;
              left: 0;
            }
          }
        }
      }
    }

    & ~ .distribution-wrap {
      & ~ .open-cases {
        &.open-cases-event {
          &.open-cases-breezy {
            & > section {
              @include before() {
                top: -161px;
              }
            }
          }
        }
      }
    }
  }
}

.main {
  &.distributions-active {
    .open-cases.open-cases-breezy.open-cases-breezy {
      margin-top: 0;
      margin-bottom: -74px;
      padding-top: 177px !important;
      max-height: initial;

      @media (max-width: 768px) {
        padding-top: 170px !important;
      }

      @include before() {
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, #240943 12.76%, rgba(38, 9, 70, 0) 33.15%),
          radial-gradient(28.23% 112.22% at 50% 58.59%, rgba(7, 11, 89, 0.288) 45.82%, rgba(69, 68, 117, 0) 94.24%);
        top: 0;
        left: 0;

        @media (max-width: 1280px) {
          display: block !important;
          background: linear-gradient(180deg, #240943 12.76%, rgba(38, 9, 70, 0) 33.15%),
            linear-gradient(0deg, rgba(19, 7, 44, 0.4), rgba(19, 7, 44, 0.4));
        }
      }

      & > section {
        position: relative;
        z-index: 4;
        width: 100%;
        height: 100%;
        display: flex;
        align-content: center;
        flex-direction: column;
        justify-content: center;
      }
    }

    .distribution-wrap + .open-cases.open-cases-breezy.open-cases-breezy {
      margin-top: -70px;

      // @media (max-width: 1280px) {
      //   margin-top: -138px;
      //   display: flex;
      //   align-items: center;
      //   padding-top: 147px !important;
      // }

      @media (max-width: 768px) {
        padding-top: 87px !important;
      }
    }

    .distribution-wrap + .cases-all {
      overflow: visible;

      .row {
        & > :first-child {
          margin-top: -144px;
          padding-top: 145px;

          @media (max-width: 480px) {
            padding-top: 120px;
          }
        }
      }
    }
  }
}

.stats {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 2;
  transform: translateX(-50%);
  margin-bottom: 20px;
  text-align: center;

  &:before {
    @extend %before-after;
    pointer-events: none;
    background: url(../images/bg-sales.svg) no-repeat 0 0;
    width: 954px;
    height: 75px;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 725px;
    height: 42px;
    position: relative;
    border-radius: 21px;
    margin-top: 15px;
    white-space: nowrap;
    padding: 0 30px;
    z-index: 3;

    li {
      color: #ccc;
      font-size: 14px;
      font-weight: 500;
      line-height: 42px;
      letter-spacing: 1px;
      position: relative;
      display: inline-block;
      text-transform: uppercase;
      max-height: 42px;
    }

    & > :nth-child(1) {
      .stats-img {
        width: 32px;
        height: 32px;
        display: inline-block;
        vertical-align: top;
        margin-right: 16px;
        position: relative;
        top: 4px;
        background: url(../images/icon-stats-1.svg) no-repeat 0 0;

        &:before {
          @extend %before-after;
          top: 0;
          left: 0;
          background: url(../images/icon-stats-1-3.svg) no-repeat 0 0;
          width: 32px;
          height: 32px;
          display: block;
          animation: spin 8s linear infinite;
        }

        &:after {
          @extend %before-after;
          top: 0;
          left: 0;
          background: url(../images/icon-stats-1-2.svg) no-repeat 0 0;
          width: 32px;
          height: 32px;
          display: block;
        }
      }
    }

    & > :nth-child(2) {
      .stats-img {
        background: url(../images/icon-stats-2.svg) no-repeat 0 0;
        width: 112px;
        height: 116px;
        display: inline-block;
        margin-top: -38px;
        vertical-align: top;
        margin-right: -32px;
      }
    }

    & > :nth-child(3) {
      .stats-img {
        background: url(../images/icon-stats-3.svg) no-repeat 0 0;
        width: 118px;
        height: 116px;
        display: inline-block;
        margin-top: -38px;
        vertical-align: top;
        margin-right: -32px;
      }
    }
  }

  .num {
    font-size: 16px;
    font-weight: bold;
    line-height: 38px;
    letter-spacing: 0.4px;
    color: #a97aff;

    vertical-align: top;
    display: inline-block;
  }
}

.main-bannes-sales {
  position: relative;

  & ~ .open-cases {
    &.open-cases-event {
      &.open-cases-breezy {
        position: relative;
        padding-top: 177px !important;

        @include before() {
          width: 100%;
          height: 100%;
          background: linear-gradient(180deg, #240943 12.76%, rgba(38, 9, 70, 0) 33.15%),
            radial-gradient(28.23% 112.22% at 50% 58.59%, rgba(7, 11, 89, 0.288) 45.82%, rgba(69, 68, 117, 0) 94.24%);
          top: 0;
          left: 0;

          @media (max-width: 1280px) {
            display: block !important;
            background: linear-gradient(180deg, #240943 12.76%, rgba(38, 9, 70, 0) 33.15%),
              linear-gradient(0deg, rgba(19, 7, 44, 0.4), rgba(19, 7, 44, 0.4));
          }
        }

        @media (max-width: 768px) {
          padding-top: 87px !important;
        }

        & > section {
          position: relative;

          @media (max-width: 768px) {
            width: 100%;
          }
        }

        .breezy-home-content {
          position: relative;
          z-index: 5;
        }
      }
    }
  }
}

.main-banner {
  background: url(../images/bg-main-banner.webp) no-repeat 0 0;
  width: 1315px;
  height: 131px;
  margin: 0 auto -30px auto;
  position: relative;
  left: 5px;
  top: 0;
  z-index: 4;

  &-text {
    font-size: 16px;
    text-shadow: 0px 2px 0px #780091;
    color: #ffc600;
    font-weight: 500;

    span {
      color: #f9e6ff;
    }
  }

  &-percent {
    font-size: 30px;
    color: #ffc600;
    text-align: center;
    text-shadow:
      0px 3px 0px #d73806,
      0px 8px 8px rgba(14, 0, 44, 0.7);
    font-weight: bold;
    margin: -4px 12px 0 12px;
  }

  &-text-wrap {
    display: flex;
    align-items: center;
    padding-top: 52px;
    text-align: center;
    justify-content: center;
    text-transform: uppercase;
    padding-left: 40px;
    min-height: 92px;
  }

  &-timer-wrap {
    position: absolute;
    top: 38px;
    right: 145px;

    .title {
      position: relative;
      font-weight: 300;
      margin: -3px 0 5px 80px;
      text-indent: 5px;

      &:before {
        @extend %before-after;
        top: -8px;
        left: -26px;
        background: url(../images/main-banner-bg-title.webp) no-repeat 0 0;
        width: 168px;
        height: 32px;
      }

      span {
        position: relative;
        z-index: 2;
        color: rgba(247, 192, 254, 0.7);
        font-size: 14px;
      }
    }
  }

  &-timer {
    font-size: 20px;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    color: rgba(211, 44, 230, 0.4);
    font-weight: 700;
    position: relative;
    margin-top: 3px;
    padding-right: 10px;

    span {
      text-shadow: 0px 2px 0px #3a004e;
      color: #d32ce6;
      margin: 0 10px;

      &.day {
        min-width: 44px;
        height: 32px;
        border-radius: 12px;
        display: inline-flex;
        align-items: center;
        padding: 2px 8px;
        text-align: center;
        margin: 0 11px 0 0;
        position: relative;
        text-shadow:
          0px 2px 0px #3a004e,
          0px 0px 40px #c131e4,
          0px 0px 12px rgba(193, 49, 228, 0.64);

        &:before {
          @extend %before-after;
          width: 62px;
          height: 42px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) skew(-20deg, 0deg);
          background: linear-gradient(180deg, #d32ce6 0%, rgba(76, 0, 85, 0.12) 100%);
          opacity: 0.2;
          margin-left: -1px;
        }

        .num {
          font-size: 20px;
          letter-spacing: 0.03em;
          line-height: 18.03px;
          text-align: center;
          color: #ffffff;
          text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
        }

        .text {
          font-weight: bold;
          font-size: 16px;
          letter-spacing: 0.03em;
          line-height: 18.03px;
          text-align: left;
          color: #ffffff;
          text-shadow:
            0px 2px 0px #3a004e,
            0px 0px 40px #c131e4,
            0px 0px 12px rgba(193, 49, 228, 0.64);
        }
      }

      &.days {
        color: #fefc00;
        text-shadow: 0px 0px 20px #ffc54bb3;
      }

      &.seconds {
        color: #ffc600;
      }
    }
  }
}

.case-play .btn,
.case-play .open-case {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 300px;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.sales-timer-off {
  .open-cases {
    margin-bottom: -10px;

    .stats:before {
      background: url(../images/bg-sales-off.png) no-repeat 0 0;
    }
  }
}

[data-pathname^="/search/"] {
  #childrenBlock {
    position: relative;

    &:before {
      content: "";
      height: 100%;
      background: linear-gradient(to top, rgba(11, 2, 32, 0) 0%, rgba(44, 6, 87, 0.7) 79.92%, #020111 100%);
      width: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
    }
  }
}

.page-search {
  padding-top: 60px;
  position: relative;
  z-index: 2;

  .title {
    font-size: 30px;
    font-weight: 400;
    line-height: 0.8;
    color: #7d92c8;
    text-align: center;
    margin-bottom: 36px;

    span {
      text-shadow:
        -1px -1px 0 #ff00b6,
        1px -1px 3px #ff00b6,
        -1px 1px 3px #ff00b6,
        1px 1px 3px #ff00b6,
        0 0 3px #ff00b6;
      color: #f7dbff;
      -webkit-text-stroke: 1px #c131e4;
      margin-left: 10px;
      font-weight: 600;
    }
  }

  .cases {
    padding: 50px 70px 0 70px;

    .case-row {
      background: none;

      &:after,
      &:before {
        display: none;
      }
    }
  }
}

.search-block-title {
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 0 0 36px #b328c9;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 2px;
  text-align: center;
  color: #d32ce6;

  span {
    position: relative;
    text-transform: uppercase;

    &:after {
      @extend %before-after;
      width: 320px;
      height: 4px;
      background-image: linear-gradient(to right, rgba(32, 8, 66, 0), #870b95);
      right: calc(100% + 10px);
      top: 50%;
      transform: translateY(-50%);
    }

    &:before {
      @extend %before-after;
      width: 320px;
      height: 4px;
      background-image: linear-gradient(to right, #870b95, rgba(32, 8, 66, 0));
      left: calc(100% + 10px);
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.open-cases {
  position: relative;
  padding-top: 120px;
  margin-top: -55px;
  margin-bottom: 0;

  &.open-cases-breezy {
    margin-bottom: -64px;

    &:before,
    &:after {
      display: none;
    }

    & > section {
      &:before {
        background: linear-gradient(180deg, #5a036f 14%, rgba(65, 9, 79, 0) 54.79%);
        opacity: 0.4;
      }
    }

    .breezy-home-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: -8px;

      .page-timer {
        margin-bottom: 21px;
        width: 637px;

        &:before {
          background: url(../images/page-timer-bg-home.svg) no-repeat 0 0;
        }
      }

      .subtitle {
        font-size: 48px;
        line-height: 129px;
        text-transform: uppercase;
        text-align: center;
        color: #fff;
        text-shadow: 0px 0px 49.9939px #13072c;
        margin: 0 auto -12px auto;
        font-family: "Russo One";
        min-height: 38px;
        max-width: 100%;

        .pangolin-font {
          font-family: "Pangolin";
          font-weight: 900;
          font-style: italic;
          font-size: 96px;

          @media (max-width: 1280px) {
            font-size: 48px;
          }

          @media (max-width: 768px) {
            font-size: 32px;
          }
        }

        .knewave-font {
          font-family: "Knewave";
          font-weight: 900;
          font-style: italic;
          font-size: 96px;

          @media (max-width: 1280px) {
            font-size: 48px;
          }

          @media (max-width: 768px) {
            font-size: 32px;
          }
        }

        @media (max-width: 1280px) {
          font-size: 24px;
          line-height: 64px;
          text-shadow: 0px 4px 0px #13072c;
          margin-bottom: -13px;
        }

        @media (max-width: 768px) {
          font-size: 18px;
          line-height: 150%;
          margin-bottom: 7px;
          width: 100%;
        }
      }

      .breezy-home-title {
        font-size: 64px;
        line-height: 129px;
        text-transform: uppercase;
        text-align: center;
        color: #fff;
        text-shadow: 0px 0px 49.9939px #13072c;
        margin: 0 auto -12px auto;
        font-family: "Russo One";
        min-height: 38px;
        max-width: 100%;

        .pangolin-font {
          font-family: "Pangolin";
          font-weight: 900;
          font-style: italic;
          font-size: 96px;

          @media (max-width: 1280px) {
            font-size: 48px;
          }

          @media (max-width: 768px) {
            font-size: 32px;
          }
        }

        .knewave-font {
          font-family: "Knewave";
          font-weight: 900;
          font-style: italic;
          font-size: 96px;

          @media (max-width: 1280px) {
            font-size: 48px;
          }

          @media (max-width: 768px) {
            font-size: 32px;
          }
        }

        @media (max-width: 1280px) {
          font-size: 32px;
          line-height: 64px;
          text-shadow: 0px 4px 0px #13072c;
          margin-bottom: -13px;
        }

        @media (max-width: 768px) {
          font-size: 24px;
          line-height: 150%;
          margin-bottom: 7px;
          width: 100%;
        }
      }

      .breezy-home-btn {
        text-decoration: none;
        background: rgba(255, 198, 0, 0.2);
        box-shadow:
          0px 0px 16px rgba(255, 198, 0, 0.64),
          inset 0 0 0 4px #ffc600;
        border-radius: 32px;
        padding: 10px 32px;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.05em;
        color: #ffffff;
        text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.4);
        text-transform: uppercase;
        position: relative;
        display: flex;
        align-items: center;
        height: 64px;
        transition: all 0.5s;

        @media (max-width: 1280px) {
          font-size: 16px !important;
        }

        @media (max-width: 560px) {
          font-size: 10px !important;
        }

        &:hover {
          background: rgba(255, 198, 0, 0.48);
          box-shadow:
            0px 0px 16px rgba(255, 198, 0, 0.64),
            inset 0 0 0 6px #ffc600;
        }
      }
    }

    &.open-cases-battle-pass.open-cases-breezy {
      margin-top: -10px;
      margin-bottom: -73px;

      img,
      video {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        z-index: 1;
      }

      &:after,
      &:before {
        @extend %before-after;
        background: linear-gradient(180deg, rgba(20, 0, 57, 0) 55.99%, rgba(10, 0, 28, 0.4) 100%),
          radial-gradient(50% 208.7% at 50% 50%, rgba(20, 0, 57, 0) 79.39%, rgba(19, 0, 44, 0.64) 100%),
          radial-gradient(50% 100% at 50% 100%, rgba(136, 71, 255, 0.2) 0%, rgba(211, 44, 230, 0) 100%);
        opacity: 0.4;
        // top: -1px;
        top: 0;
        display: block !important;
        width: 100%;
        height: 100%;
        z-index: 3;
      }

      &:after {
        background: linear-gradient(180deg, rgba(19, 7, 44, 0) 0%, #13072c 100%),
          radial-gradient(50% 820.38% at 50% 50%, rgba(19, 7, 44, 0) 89.34%, #13072c 100%);
        opacity: 1;
        z-index: 2;
      }

      .breezy-home-content {
        position: relative;
        z-index: 3;

        @media (max-width: 480px) {
          padding-top: 80px;
        }

        .page-timer {
          margin-bottom: 29px;
        }
      }

      .breezy-home-btn {
        padding: 2px 32px;
        height: 64px;
        background: rgba(255, 198, 0, 0.2);
        box-shadow: 0px 0px 16px rgba(255, 198, 0, 0.64);
        border-radius: 32px;
        backdrop-filter: blur(20px);
      }
    }
  }
}

.open-cases.open-cases-breezy,
.open-cases.open-cases-home {
  background-size: 100%;
  padding-bottom: 45px;
  position: relative;
  padding-top: 40px;
  margin-top: 0;
  margin-bottom: -74px;

  @media (max-width: 768px) {
    height: 440px;
    display: flex;
    align-items: center;
    padding-bottom: 0;
  }

  h1 {
    font-size: 40px;
    font-weight: 900;
    line-height: 1;
    text-align: center;
    color: $white;
    margin: 32px 0 12px 0;
    text-transform: uppercase;
    text-shadow:
      -1px -1px 0 #c131e4,
      1px -1px 0 #c131e4,
      -1px 1px 0 #c131e4,
      0px 0px 80px #c131e4,
      0px 0px 24px rgba(193, 49, 228, 0.64);
    position: relative;
    z-index: 2;

    @include large() {
      padding: 0 16px;
    }

    @media (max-width: 768px) {
      text-align: left;
      margin-bottom: 12px;
      margin-top: 0;
      font-size: 32px;
    }

    &.not-auth-h1 {
      display: none;
    }
  }

  h3 {
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    color: #d32ce6;
    text-transform: uppercase;
    margin-bottom: 45px;
    position: relative;
    z-index: 2;

    @include large() {
      padding: 0 16px;
    }

    @media (max-width: 768px) {
      text-align: left;
    }
  }

  &:before {
    @extend %before-after;
    background: url(../images/terror-1.webp) 0 0 no-repeat;
    width: 549px;
    height: 628px;
    top: 1px;
    background-size: contain;
    z-index: 4;
    pointer-events: none;

    @include desktop() {
      display: none !important;
    }
  }

  &:after {
    @extend %before-after;
    background: url(../images/terror-2.webp) 0 0 no-repeat;
    right: 0;
    width: 584px;
    height: 628px;
    top: 1px;
    background-size: contain;
    z-index: 4;
    pointer-events: none;

    @include desktop() {
      display: none !important;
    }
  }

  .img-bg-breezy {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }

  &.open-cases-breezy {
    margin-bottom: -73px;
    margin-top: -30px;
    min-height: 470px;
    padding-top: 170px !important;

    @include before() {
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, #240943 12.76%, rgba(38, 9, 70, 0) 33.15%),
        radial-gradient(28.23% 112.22% at 50% 58.59%, rgba(7, 11, 89, 0.288) 45.82%, rgba(69, 68, 117, 0) 94.24%);
      top: 0;
      left: 0;

      @media (max-width: 1280px) {
        display: block !important;
        background: linear-gradient(180deg, #240943 12.76%, rgba(38, 9, 70, 0) 33.15%),
          linear-gradient(0deg, rgba(19, 7, 44, 0.4), rgba(19, 7, 44, 0.4));
      }
    }

    @media (max-width: 768px) {
      height: 460px;
      min-height: 440px;
      padding-top: 138px !important;
    }

    // &:before,
    &:after {
      display: none;
    }

    & > section {
      &:before {
        background: linear-gradient(180deg, #5a036f 14%, rgba(65, 9, 79, 0) 54.79%);
        opacity: 0.4;
      }
    }

    .breezy-home-content {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: -8px;
      z-index: 5;

      .page-timer {
        margin-bottom: 21px;

        &:before {
          background: url(../images/page-timer-bg-home.svg) no-repeat 0 0;
        }
      }

      .breezy-home-btn {
        text-decoration: none;
        background: rgba(255, 198, 0, 0.2);
        box-shadow:
          0px 0px 16px rgba(255, 198, 0, 0.64),
          inset 0 0 0 4px #ffc600;
        border-radius: 32px;
        padding: 10px 32px;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.05em;
        color: #ffffff;
        text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.4);
        text-transform: uppercase;
        position: relative;
        display: flex;
        align-items: center;
        height: 64px;
        transition: all 0.5s;
        backdrop-filter: blur(20px);

        @media (max-width: 359px) {
          padding: 10px 10px;
        }

        &-timer {
          padding: 13px 20px;
          background: linear-gradient(180deg, rgba(3, 0, 11, 0.52) 0%, rgba(23, 21, 28, 0.52) 100%);
          border-radius: 4px 25px 25px 4px;
          height: 50px;
          margin: 0 -25px 0 20px;
          letter-spacing: 0.9px;
          display: flex;
          align-items: center;
          text-transform: none;

          @media (max-width: 359px) {
            padding: 13px 10px;
            margin: 0 -5px 0 10px;
          }

          span {
            color: #eb4b4b;
            margin-left: 4px;
          }
        }

        &:hover {
          background: rgba(255, 198, 0, 0.48);
          box-shadow:
            0px 0px 16px rgba(255, 198, 0, 0.64),
            inset 0 0 0 6px #ffc600;

          .breezy-home-btn-text {
            text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.64);
          }

          .breezy-home-btn-timer {
            text-shadow: 0px 0px 6px rgba(211, 44, 230, 0.0001);
          }
        }
      }
    }
  }

  .img-bg-breezy {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
}

.open-cases.open-cases-home {
  padding-top: 156px;
  margin-top: -126px;

  .banner-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.2;
  }

  section {
    @include before() {
      width: 100%;
      height: 100%;
      background: radial-gradient(50% 208.7% at 50% 50%, rgba(20, 0, 57, 0) 36.17%, rgba(19, 0, 44, 0.64) 76.13%);
      top: 0;
      left: 0;
    }
  }
}

.main[data-region="cis"] {
  .open-cases.open-cases-home {
    padding-top: 148px;
  }

  &[data-host="justcases.org"],
  &[data-host="csgohappy.com"],
  &[data-host="key-dropp.net"],
  &[data-host="hellcases.org"] {
    .open-cases.open-cases-home {
      padding-top: 42px;
    }
  }
}

.main[data-host="justcases.org"],
.main[data-host="csgohappy.com"],
.main[data-host="key-dropp.net"],
.main[data-host="hellcases.org"] {
  .open-cases.open-cases-home {
    padding-top: 42px;

    h1 {
      margin-top: 12px;
    }
  }
}

.main[data-host="cscase.com"] {
  .open-cases {
    &.open-cases-breezy {
      &.open-cases-battle-pass {
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 768px) {
          padding-top: 0 !important;
        }
      }
    }
  }

  .distribution-wrap {
    & ~ .open-cases {
      &.open-cases-breezy {
        &.open-cases-battle-pass {
          display: block;
        }
      }
    }
  }
}

.daily-prizes {
  height: 116px;
}

.event-timer {
  @include inline-flex(row, center, center, false);
  background: rgba(19, 7, 44, 0.6);
  backdrop-filter: blur(4px);
  border-radius: 8px;
  padding: 4px 8px 4px 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: rgba(213, 185, 255, 0.8);

  .icon {
    color: #ff2c3b;
    box-shadow: 0 0 12px 1px #ff2c3b;
    background: #fef2c8;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    @include flex(false, center, center, false);
  }

  .timer {
    flex-grow: 1;
    text-align: center;
  }

  .day {
    margin-right: 4px;
    color: #fef2c8;
    text-shadow:
      -1px -1px 0 rgba(235, 75, 75, 0.48),
      1px -1px 0 rgba(235, 75, 75, 0.48),
      -1px 1px 0 rgba(235, 75, 75, 0.48),
      1px 1px 0 rgba(235, 75, 75, 0.48);
  }
}

// .slider {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   position: relative;
//   width: 100%;
//   max-width: 800px;
//   margin: 0 auto;
//   overflow: hidden;
// }

.slider-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: opacity 0.5s ease-in-out;
}

.slider-content.fade-out {
  opacity: 0;
}

.slider-content.fade-in {
  opacity: 1;
}

.slider-button {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  padding: 10px 15px;
  font-size: 18px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.prev-button {
  left: 10px;
  z-index: 5;
}

.next-button {
  right: 10px;
  z-index: 5;
}

// img {
//   max-width: 100%;
//   height: auto;
//   border-radius: 5px;
// }

// .hide {
//   display: none;
// }

.slider-indicators {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 50%;
  bottom: 30px;
  margin-top: 10px;
}

.slider-indicator {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: white;
  border-radius: 50%;
  opacity: 0.5;
  transition: opacity 0.3s;
  z-index: 3;
}

.slider-indicator.active {
  opacity: 1;
}

.slider {
  margin-top: -47px;
  // @media(max-width: 1200px) {
  //   margin-top: -20px;
  // }

  @media (max-width: 480px) {
    margin-top: -188px;
  }
}

.swiper-pagination-bullet {
  background-color: rgba(230, 230, 230, 0.8); /* Цвет неактивных bullets */
}

.swiper-pagination-bullet-active {
  background-color: rgba(153, 86, 254, 0.5); /* Цвет активного bullet */
}


